/** @jsx jsx */
import { jsx } from 'theme-ui'

const currentYear = new Date().getFullYear()
const Footer = () => (
  <footer 
    className="site-footer"
    sx={{
      //bg: 'primary'
    }}
  >
    <div className="container">
      <p> Copyright 2016-{currentYear} blk.SMITH.LCC </p>
    </div>
  </footer>
)

export default Footer