import React from 'react';
import { Link } from "gatsby"
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

export default function Navigation()  {
  const [showMenu, setShowMenu] = React.useState(false);

  const handleToggleClick = (showMenu) => {
    return showMenu ? false : true
  }

  const MenuItems = [
    {
      path: "/",
      title: "Home"
    },
    {
      path: "/about",
      title: "About"
    },
    {
      path: "/blog",
      title: "Blog"
    },
    {
      path: "/contact",
      title: "お問い合わせ"
    },
  ]
  const ListLink = (props) => (<li><Link to={props.to}>{props.children}</Link></li>)
  const listMenuItems = MenuItems.map((menuItem, index) => 
  <ListLink key={index} to={menuItem.path}>{menuItem.title}</ListLink>
  )
  const navStyle = {
    menu:{
      "ul":{
        bg: "primary",
      },
    },
    theme:{
      display:["block", "block", "block", "none"],
      p:" 25px 20px 20px",
    },
    border: {
      bg:"borderColor",
      borderTop:"1px solid transparent",
      display:["block", "block", "block", "none"],
    }
  }

  return (
    <div>
      <nav className="site-navigation" sx={navStyle.menu}>
        <button onClick={()=>{setShowMenu(handleToggleClick(showMenu))}} className={"menu-trigger" + (showMenu ? " is-active" : "")}>
          <div className="icon-menu-line"><RiMenu3Line/></div>
          <div className="icon-menu-close"><RiCloseLine/></div>
        </button>
        <ul>
          {listMenuItems}
          <div sx={navStyle.border}></div>
          <div sx={navStyle.theme}></div>
        </ul>
      </nav>
    </div>
  );
}
