import React from "react"
import { Link } from "gatsby"

const Logo = (props) => (
  <div>
    <Link to="/" className="site-logo" title="Logo">
      <img className="title title-left" src="/assets/siteLogo-left.png" alt="Logo" />
      <img className="title title-right" src="/assets/siteLogo-right.png" alt="Logo" />
    </Link>
  </div>
)

export default Logo