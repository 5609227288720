/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"


import Header from "./header"
import Logo from "./logo"
import Navi1 from "./navi";

import "../assets/scss/style.scss"
import Footer from "./footer";
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles({
  navigation: {
    margin: "0 0 0 auto",
  }
})

const query = graphql`
query LayoutQuery {
  site {
    siteMetadata {
      siteTitle: title
    }
  }
}
`

const Layout = ({children, className, props}) => {
  const classes = useStyle()
  const { site } = useStaticQuery(query)
  const { siteTitle } = site.siteMetadata


  return (
    <div className="primary-container">
      <Header>
        <Logo title={siteTitle} />
      </Header>
      <Header classes={classes.navigation}>
        <Navi1/>
      </Header>
      <main className={"container " + className + " effect"}>
        <div className="context">
          {children}
        </div>
        <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
        </ul>
      </main>
      <Footer/>
    </div>
  )
}

export default Layout
